/* Landing Page Styling */
.landing-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  background-color: #1E1E2E;
  color: #E5E5E5;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  text-align: center;
  margin-top: 1100px; /* Adjust this value based on your header's height */
}

/* Hero Section */
.hero {
  margin-top: 50px;
  padding: 60px 20px;
  background-color: #282845;
  border-radius: 12px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

.hero-title {
  font-size: 3rem;
  color: #ffffff;
  margin-bottom: 20px;
}

.hero-subtitle {
  font-size: 1.2rem;
  color: #CCCCCC;
  margin-bottom: 30px;
  line-height: 1.6;
}

.cta-button {
  font-size: 1.2rem;
  padding: 15px 30px;
  background-color: #8ab4f8;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #729ce4;
}

/* Features Section */
.features {
  margin-top: 50px;
  padding: 40px 20px;
}

.features-list {
  list-style: none;
  padding: 0;
}

.features-list li {
  font-size: 1.1rem;
  margin-bottom: 15px;
  color: #A3A3B3;
}

.features-list li strong {
  color: #ffffff;
}

/* How It Works Section */
.how-it-works {
  margin-top: 50px;
  padding: 40px 20px;
}

.steps-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.step {
  flex: 1 1 calc(33% - 20px);
  background-color: #282845;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  text-align: left;
}

.step h3 {
  font-size: 1.5rem;
  color: #8ab4f8;
  margin-bottom: 10px;
}

.step p {
  font-size: 1rem;
  color: #A3A3B3;
  line-height: 1.5;
}

/* Testimonials Section */
.testimonials {
  margin-top: 50px;
  padding: 40px 20px;
}

.testimonial {
  font-size: 1.2rem;
  font-style: italic;
  color: #CCCCCC;
  margin-top: 20px;
}

/* Pricing Section */
.pricing {
  margin-top: 50px;
  padding: 40px 20px;
  background-color: #282845;
  border-radius: 12px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

.pricing .section-title {
  font-size: 2rem;
  color: #8ab4f8;
  margin-bottom: 15px;
}

/* Footer Section */
.footer {
  margin-top: 50px;
  padding: 20px 0;
  background-color: #1E1E2E;
  color: #A3A3B3;
  text-align: center;
  font-size: 0.9rem;
  border-top: 1px solid #333;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.2);
  border-top: 4px solid #000;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 0.8s linear infinite;
  display: inline-block;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}


/* Responsive Design */
@media (max-width: 1350px) {
  .landing-container {
      margin-top: 1150px;
  }
}

@media (max-width: 928px) {
  .landing-container {
      margin-top: 1300px;
  }
}


@media (max-width: 865px) {
  .landing-container {
      margin-top: 1300px;
  }
}

@media (max-width: 855px) {
  .landing-container {
      margin-top: 1350px;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .landing-container {
      margin-top: 1400px;
  }
}

@media (max-width: 740px) {
  .landing-container {
      margin-top: 1450px;
  }
}

@media (max-width: 652px) {
  .landing-container {
      margin-top: 1450px;
  }
}

@media (max-width: 640px) {
  .landing-container {
      margin-top: 1600px;
  }
}

@media (max-width: 592px) {
  .landing-container {
      margin-top: 1650px;
  }
}

@media (max-width: 562px) {
  .landing-container {
      margin-top: 1750px;
  }
}

@media (max-width: 510px) {
  .landing-container {
      margin-top: 1850px;
  }
}

@media (max-width: 466px) {
  .landing-container {
      margin-top: 1950px;
  }
}

@media (max-width: 428px) {
  .landing-container {
      margin-top: 2050px;
  }
}

@media (max-width: 412px) {
  .landing-container {
      margin-top: 2150px;
  }
}
