/* General Styles */
body {
  font-family: 'Inter', sans-serif;
  background: #1D1E26;
  color: #E5E7EB;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-app {
  display: flex;
  width: 100vw;
  max-width: 1200px;
  background: #2E2F3E;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  margin-top: 60px;
}

/* Sidebar */
.sidebar {
  width: 250px;
  background: #1F1F2D;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.new-chat-button {
  background-color: #6366F1;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.new-chat-button:hover {
  background-color: #4F46E5;
}

.sidebar-title {
  color: #9CA3AF;
  font-weight: bold;
  margin: 0;
}

.chat-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  color: #E5E7EB;
  cursor: pointer;
  border-radius: 8px;
  transition: background 0.2s;
}

.chat-item:hover {
  background: #3A3B4D;
}

.selected {
  background: #6366F1;
}

.edit-icon {
  font-size: 1rem;
  color: #A1A1AA;
  cursor: pointer;
}

.edit-icon:hover {
  color: #E5E7EB;
}

/* Main Content Area */
.main-content {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.chat-header {
  color: #FFFFFF;
  font-size: 1.5rem;
  text-align: center;
}

.main-section {
  color: #9CA3AF; /* Softer gray color */
  font-size: 1rem;
  text-align: center;
  max-width: 600px;
  margin: 20px auto; /* Space around the instruction text */
  padding: 10px;
  line-height: 1.5;
}



.chat-container {
  max-height: 70vh; /* Adjust as needed */
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
  display: flex;
}

.chat-message p {
  white-space: pre-wrap; /* Preserves formatting, spaces, and line breaks */
}

/* User message bubble, aligned right */
.chat-message.user {
  align-self: flex-end;
  background-color: #2c4167; /* Blue bubble */
  color: white;
  padding: 10px 15px;
  border-radius: 15px;
  margin: 5px 0;
  max-width: 70%;
  text-align: left;
}

/* Bot response, aligned left */
.chat-message.bot {
  align-self: flex-start;
  color: white;
  padding: 10px 15px;
  border-radius: 15px;
  margin: 5px 0;
  max-width: 70%;
  text-align: left;
}

/* Custom scrollbar styling */
.chat-container::-webkit-scrollbar {
  width: 8px;
}

.chat-container::-webkit-scrollbar-thumb {
  background-color: #0078fe;
  border-radius: 10px;
}

.chat-container::-webkit-scrollbar-track {
  background-color: #e0e0e0;
}


.chat-input-container {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  margin-top: auto;
}

.chat-input {
  flex: 1;
  padding: 15px;
  border-radius: 8px;
  border: none;
  outline: none;
  background-color: #3A3B4D;
  color: #E5E7EB;
  resize: none;
  font-size: 1rem;
  transition: height 0.2s ease;
  max-height: 150px;
  overflow-y: auto;
}

.chat-input::placeholder {
  color: #9CA3AF;
}

.chat-buttons {
  display: flex;
  align-items: center;
}

.chat-send-button {
  background-color: #6366F1;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.chat-send-button:hover {
  background-color: #4F46E5;
}

.upload-button {
  color: #9a86fd;
  font-size: 1.5rem;
  cursor: pointer;
  margin: 10px;
}

.chat-send-button {
  background-color: #7a5cfa; /* Original purple color */
  color: #fff;
  padding: 12px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.2s;
  margin-left: 8px; /* Space between textarea and button */
}

.chat-send-button:hover {
  background-color: #5b43b1; /* Slightly darker on hover */
}

.chat-send-button:active {
  background-color: #4b3890; /* Even darker on click */
}

.chat-input {
  flex: 1;
  padding: 10px;
  background-color: #1a1b2e;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  outline: none;
  resize: none;
  min-height: 50px;
  max-height: 200px;
  overflow-y: auto;
  resize: none;
}

.response-container {
  margin-top: 20px;
  background-color: #1a1b2e;
  border-radius: 8px;
  padding: 15px;
}

.response-header {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.response-message {
  color: #d1d1d1;
}

.mainSection {
  flex: 1;
  justify-content: center;
  text-align: center;
  padding: 50px 20px;
}

h1 {
  font-size: 2rem;
  margin-bottom: 10px;
}

p {
  font-size: 1.2rem;
  color: #d1d1d1;
}

.upload-resume-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.upload-resume-button {
  background-color: #9a86fd; /* Match theme color */
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.upload-resume-button:hover {
  background-color: #7a6add; /* Hover color for better UX */
}

.resume-preview {
  display: flex;
  justify-content: center;
  margin-top: 10px; /* Adjust spacing as needed */
}

/* Add styling for the FilePreview component itself */
.resume-preview .file-preview {
  text-align: center;
  padding: 10px;
  background-color: #222; /* Optional styling */
  border-radius: 5px;
}

.chat-app {
  display: flex;
  height: 88vh; /* Full height to prevent pushing down content */
}

/* Sidebar background color */
.sidebar {
  background-color: #2f3e4d;
  width: 250px; /* Fixed width for sidebar */
  padding: 1rem;
  overflow-y: auto;
  height: 100%; /* Take full height of the container */
}

/* Chat item styling */
.chat-item {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  color: #ffffff;
}

/* Lighter color for each chat item */
.chat-item:hover {
  background-color: #3a4a5b; /* Slightly lighter color on hover */
}

/* Highlight for the selected chat */
.chat-item.selected {
  background-color: #50667c; /* Lighter hue for the selected chat */
}

.new-chat-button {
  width: 100%;
  background-color: #9a86fd; /* Purple color similar to the send button */
  color: #ffffff;
  padding: 10px 0;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 16px;
  transition: background-color 0.3s;
}

.new-chat-button:hover {
  background-color: #8e44ad; /* Slightly darker purple on hover */
}

.sidebar-title {
  text-align: center;
  color: #ffffff;
  font-size: 18px;
  margin: 0;
  padding-bottom: 12px;
}

/* Hide Sidebar on Mobile by Default */
@media (max-width: 768px) {
  .chat-app {
    margin-top: 80px;
  }

  .sidebar {
    position: fixed;
    top: 143px;
    left: 0;
    transform: translateX(-100%); /* Hide sidebar */
    z-index: 1000;
    width: 250px;
    height: 100vh;
    background-color: #1f1f2d;
  }

  .sidebar.open {
    transform: translateX(0); /* Show sidebar */
  }

  .close-btn {
    display: block; /* Display close button on mobile */
  }
}

@media (width: 540px) {
  .sidebar {
    top: 128px;
  }
}

@media (width: 430px) {
  .sidebar {
    top: 148px;
  }
}

@media (width: 412px) {
  .sidebar {
    top: 148px;
  }
}

@media (max-width: 375px) {
  .sidebar {
    top: 140px;
  }
}

/* Hamburger Icon */
.hamburger-icon {
  position: fixed; /* Position it relative to the parent container */
  font-size: 1.5rem;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  display: none; /* Hide by default */
}

@media (max-width: 768px) {
  .hamburger-icon {
    display: flex; /* Show hamburger menu on mobile */
    padding: 15px;
    left: 15px;
  }
}

/* Default styling for chat-title (Desktop/Tablet) */
.chat-title {
  white-space: nowrap; /* Prevents wrapping by default */
  overflow: hidden; /* Hides text overflow */
  text-overflow: ellipsis; /* Adds "..." when text overflows */
}

/* Mobile-specific styles for wrapping chat-title */
@media (max-width: 768px) {
  .chat-title {
    font-size: 12px;
    white-space: normal; /* Allow wrapping on smaller screens */
    word-wrap: break-word; /* Break long words if necessary */
    overflow: visible; /* Ensure all content is displayed */
  }
}

/* Editable Title Input */
.edit-title-input {
  flex: 1;
  border: none;
  background-color: #3A3B4D;
  color: #E5E7EB;
  padding: 8px;
  border-radius: 8px;
  outline: none;
  font-size: 1rem;
}

.edit-title-input:focus {
  background-color: #4F46E5;
  color: white;
}

.typing-indicator {
  font-size: 18px; /* Bigger text */
  color: whitesmoke; /* Neutral color for better contrast */
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.typing-indicator .dot {
  font-size: 18px; /* Match the size of the text */
  color: whitesmoke; /* Same color for consistency */
  margin-left: 2px;
  animation: blink 1.5s infinite;
}

.typing-indicator .dot:nth-child(2) {
  animation-delay: 0.3s;
}

.typing-indicator .dot:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

