/* General Page Style */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #1A1A2E;
  color: #E5E5E5;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.settings-container {
  background-color: #1E1E2E;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  width: 80%; /* Increase width to make the container wider */
  max-width: 800px; /* Set max-width to keep it balanced on larger screens */
  text-align: center;
  margin: auto; /* Center the container */
}

.title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Section Styling */
section {
  margin-bottom: 30px;
}

h2 {
  font-size: 22px;
  margin-bottom: 10px;
  color: #E5E5E5;
}

p {
  font-size: 16px;
  color: #aaa;
}

/* Button Styles */
button {
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.delete-btn, .cancel-btn {
  background-color: #8b5cf6;
  color: white;
  margin-top: 10px;
}

.confirm-btn {
  background-color: #e63946;
  color: white;
  margin-top: 10px;
}

.delete-btn:hover, .cancel-btn:hover, .confirm-btn:hover {
  opacity: 0.85;
}

/* Status Message Styling */
.status-message {
  margin-top: 15px;
  font-size: 16px;
  color: #fca311;
}
