.file-handler-buttons {
    display: flex;

  
  .file-handler-button {
    padding: 12px 20px;
    font-size: 16px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
    color: white;
    text-decoration: none; /* For view button link styling */
  }
  
  .upload-button:hover {
    background-color: #6c44d1;
  }
  
  .view-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .view-button:hover {
    background-color: #0056b3;
  }
  
  .file-icon {
    font-size: 20px;
  }
  
  .view-resume-text {
    font-size: 16px;
    color: #007bff;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
  }
  
  .view-resume-text:hover {
    text-decoration: underline;
    color: #0056b3;
  }
  
  .file-icon {
    font-size: 20px;
  }
}