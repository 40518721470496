  .settings-container {
    background-color: #1E1E2E;
    padding: 40px;
    margin-top: 100px;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 600px;
    text-align: center;
  }
  
  .title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  /* Section Styling */
  section {
    margin-bottom: 30px;
  }
  
  h2 {
    font-size: 22px;
    margin-bottom: 10px;
    color: #E5E5E5;
  }
  
  p {
    font-size: 16px;
    color: #aaa;
  }
  
  /* Button Styles */
  button {
    padding: 12px 20px;
    font-size: 16px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .delete-btn, .cancel-btn, .subscribe-button {
    background-color: #8b5cf6;
    color: white;
    margin-top: 10px;
  }
  
  .confirm-btn {
    background-color: #e63946;
    color: white;
    margin-top: 10px;
  }
  
  .delete-btn:hover, .cancel-btn:hover, .confirm-btn:hover {
    opacity: 0.85;
  }
  
  /* Status Message Styling */
  .status-message {
    margin-top: 15px;
    font-size: 16px;
    color: #fca311;
  }

  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.2);
    border-top: 4px solid #007bff;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    animation: spin 0.8s linear infinite;
    display: inline-block;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  .update-resume-settings {
    margin-top: 20px;
  }
  
  .resume-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .upload-resume-btn {
    background-color: #8b5cf6; /* Match existing button colors */
    color: white;
    padding: 12px 20px;
    font-size: 16px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px; /* Align spacing with other buttons */
  }
  
  .upload-resume-btn:hover {
    opacity: 0.85; /* Consistent hover effect */
  }

  .resume-preview a {
    color: #007bff;
    text-decoration: none;
  }
  
  .resume-preview a:hover {
    text-decoration: underline;
  }
  