.navbar {
  display: flex;
  flex-direction: row; /* Ensure a single row for desktop */
  align-items: center;
  justify-content: space-evenly;
  padding: 10px 20px;
  background-color: #1A1A2E;
  color: #fff;
  width: 100%;
  box-sizing: border-box;
  position: sticky;
  top: 0;
}

.navbar-row {
  display: flex;
  align-items: center;
  gap: 20px;
}

/* Brand styling */
.brand {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
}

/* Hamburger icon styling */
.hamburger-icon {
  display: none;
  font-size: 1.5rem;
  color: #fff;
  cursor: pointer;
}

/* Row Styling */
.navbar-row {
  display: flex;
  align-items: center;
  width: 100%;
}

/* Top row and bottom row for large screens */
.top-row {
  justify-content: space-evenly;
}

.bottom-row {
  display: flex;
  gap: 20px;
  justify-content: space-evenly;
}

/* Expanded dropdown menu styles */
.bottom-row.expanded {
  display: flex;
  flex-direction: row; /* Align links horizontally */
  justify-content: center;
  width: 100%;
  margin-top: 10px;
  gap: 15px;
}

/* Specific item styles */
.file-upload-label, .file-preview, .nav-text-link {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 15px;
  border-radius: 6px;
  color: #fff;
  text-decoration: none;
  background-color: transparent;
}
.file-upload-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.file-preview {
  margin-top: 5px;
  text-align: center;
}

.file-upload-label {
  margin-top: 8px;
}

.file-preview .file-icon {
  font-size: 1.2rem;
  color: #e74c3c;
  margin-right: 8px;
}

.file-preview a {
  color: #8ab4f8;
}

.file-preview a:hover {
  text-decoration: underline;
}

.nav-text-link {
  color: white;
  text-decoration: none;
}

.nav-text-link:hover {
  color: #8ab4f8;
}

/* Profile picture styling */
.profile-link img.profile-picture {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 2px solid #4a4a6a;
}

/* Media Queries for Mobile View */

/* Tablet and below */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column; /* Stack elements vertically */
    align-items: flex-start;
  }

  .hamburger-icon {
    display: block; /* Show hamburger icon */
  }

  .navbar-row.expanded {
    display: flex; /* Show menu when expanded */
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
}

/* Mobile specific adjustments */
@media (max-width: 480px) {
  .brand {
    font-size: 1.4rem;
  }

  .file-preview, .file-upload-label {
    font-size: 0.9rem;
  }

  .profile-link img.profile-picture {
    width: 30px;
    height: 30px;
  }
}

.upgrade-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #8b5cf6;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 10px; /* Add spacing from other elements */
}

.upgrade-button:hover {
  background-color: #04a46f;
}

.upgrade-button:disabled {
  background-color: #03734d; /* Dimmed color when disabled */
  cursor: not-allowed;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.2);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
