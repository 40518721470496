/* ProfileOrLogin.css */

.profile-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .profile-link img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  .modal-popup {
    position: absolute;
    top: 50px; /* Adjust to position below the icon */
    right: 0;
    background-color: #333;
    color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 4px 0; /* Reduced padding for a tighter look */
    width: 150px;
    z-index: 1000;
  }
  
  .modal-option {
    display: block;            /* Make each option take full width */
    width: 100%;                /* Ensure it fills the modal's width */
    padding: 10px 20px;
    background: none;
    color: inherit;
    border: none;
    text-align: left;
    cursor: pointer;
    font-size: 14px;
    text-decoration: none;      /* Removes underline for the link */
  }
  
  .modal-option:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .nav-text-link {
    font-size: 16px;
    color: white;
    text-decoration: none;
    padding: 10px;
  }
  
  .nav-text-link:hover {
    color: #65658b;
  }
  
/* Open Login Modal Link */
.open-login-modal-link {
  color: #ffffff;
  font-size: 1em;
  text-decoration: none;
  margin-left: 20px; /* Adjust spacing to match other navbar items */
  cursor: pointer;
}

.open-login-modal-link:hover {
  color: #65658b;
}