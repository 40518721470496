/* App.css */

* {
  margin-top: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background-color: #1a1b2e; /* Darker background for the entire screen */
  color: white;
}

.app-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.app-content {
  margin-top: 100px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}