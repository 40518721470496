.footer {
    background-color: #1A1A2E; /* Match navbar and page styling */
    color: #ffffff;
    text-align: center;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    bottom: 0;
  }
  
  .footer p {
    margin: 0;
    font-size: 0.9rem;
    color: #cccccc;
  }
  
  .footer-links {
    margin-top: 10px;
    display: flex;
    gap: 15px;
    justify-content: center;
  }
  
  .footer-link {
    color: #8ab4f8; /* Link color */
    text-decoration: none;
    font-size: 0.9rem;
  }
  
  .footer-link:hover {
    color: #fff; /* Lighter on hover */
  }
  