/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8); /* Slightly darker overlay for consistency */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Payment Modal Container */
.payment-modal {
  position: relative;
  background-color: #1E1E2E; /* Match the file upload modal's background */
  color: #E5E5E5; /* Consistent text color */
  border-radius: 8px;
  width: 90%; /* Responsive width */
  max-width: 500px; /* Slightly larger for more content flexibility */
  padding: 30px; /* Spacious padding for a clean look */
  text-align: center; /* Centered text */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3); /* Enhanced shadow */
  animation: fadeIn 0.3s ease-in-out; /* Subtle fade-in animation */
}

/* Payment Modal Heading */
.payment-modal h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #E5E5E5; /* Match the modal title styling */
}

/* Payment Modal Text */
.payment-modal p {
  font-size: 18px;
  line-height: 1.5;
  color: #aaa; /* Subtle text color for descriptions */
  margin-bottom: 20px;
}

/* Modal Buttons */
.payment-modal button {
  background-color: #8b5cf6; /* Primary button color */
  color: white;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 10px; /* Space between buttons */
}

.payment-modal button:hover {
  background-color: #7a4bd9; /* Slightly darker on hover */
}

/* Close Button */
.payment-modal .close-button {
  color: white;
}

.payment-modal .close-button:hover {
  background-color: #d52c38; /* Darker hover effect for cancel */
}

/* Fade-in Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.2);
  border-top: 4px solid #000;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

