/* src/Login.css */

/* Overlay to cover the page */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Centered modal container with background */
.login-modal {
  position: relative;
  background-color: #252547; /* Modal background */
  color: #ffffff;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  padding: 2em;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

/* Close button in the modal */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  color: #ffffff; /* White color for visibility */
  cursor: pointer;
}

.login-title {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 1em;
  color: #ffffff;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5em;
  width: 100%;
}

label {
  font-size: 1em;
  margin-bottom: 0.5em;
  color: #ccc;
}

.login-input {
  padding: 0.8em;
  font-size: 1em;
  border: none;
  border-radius: 4px;
  background-color: #333;
  color: #ffffff;
  width: 100%;
}

.login-input:focus {
  outline: none;
  border: 1px solid #6c63ff;
}

.login-button {
  padding: 0.8em 1.5em;
  font-size: 1em;
  color: #ffffff;
  background-color: #6c63ff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1em;
  width: 100%;
}

.login-button.google {
  background-color: #4285F4;
  margin-bottom: 1em;
}

.login-button:hover {
  background-color: #574b90; /* Darker shade for hover effect */
}

/* src/Login.css */

/* Sign-up link at the bottom of the login modal */
.signup-link {
  text-align: center;
  margin-top: 1em;
  color: #ccc;
  font-size: 0.9em;
}

.signup-link a {
  color: #6c63ff;
  text-decoration: none;
  font-weight: bold;
}

.signup-link a:hover {
  text-decoration: underline;
}
