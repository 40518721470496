.file-preview {
  margin-top: 0; /* Remove the top margin */
  display: flex;
  align-items: center;
}

.file-icon {
  font-size: 32px;
  color: #ff4081;
}

.file-info {
  display: flex;
  flex-direction: column;
  text-align: center;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
